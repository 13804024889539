import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
const SocialHistory2 = () => {
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    // eating_habits_explanation: Yup.string().required("Explanation is required"),
    drinks_per_week: Yup.number()
      .nullable()
      .typeError("Drinks per week must be a number"),
    // .required("Drinks per week is required"),
    how_much_tobacco_per_day: Yup.number()
      .nullable()
      .typeError("Tobacco per day must be a number"),
    // .required("Drinks per day is required"),
  });

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm4")) || {
    eating_habits_changed: "N",
    eating_habits_explanation: "",
    drink_alcohol: "N",
    drinks_per_week: "",
    tobacco_products: "N",
    how_much_tobacco_per_day: null,
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm4", JSON.stringify(values));
    history.push("/concussion-forms/add/5");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Social History</span>
        <span className="form-progress">4/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Have your eating habits changed since your injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="eating_habits_changed">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "eating_habits_changed",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="eating_habits_changed"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Please explain</label>
                <Field
                  name="eating_habits_explanation"
                  className="field-input notes "
                  as="textarea"
                />
                <ErrorMessage
                  name="eating_habits_explanation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Do you drink alcohol?</label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="drink_alcohol">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("drink_alcohol", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="drink_alcohol"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">How many drinks per week?</label>
                <Field
                  name="drinks_per_week"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="drinks_per_week"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Do you use tobacco products?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="tobacco_products">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("tobacco_products", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="tobacco_products"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">How much per day?</label>
                <Field
                  name="how_much_tobacco_per_day"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="how_much_tobacco_per_day"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/3")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SocialHistory2;
