import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../recording2Updated/recording2.css";
import Plot from "react-plotly.js";
import PwelchGraph from "./PwelchGraph";
import { getPwelchPlot } from "../../actions/graphActions";
import { useFormik } from "formik";
import * as Yup from "yup";

const HeatMap = ({ timeLength }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const token = useSelector((state) => state.loginData.token);
  const [heatmapData, setHeatmapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [qeeg, setQeeg] = useState("Spectrogram");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const popupRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const { pwelchData } = useSelector((state) => state.pwelchPlot);

  useEffect(() => {
    const fetchPwelchData = async () => {
      try {
        const pwelch_data = await dispatch(getPwelchPlot(token, params.id));
        setHeatmapData(pwelch_data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error fetching Pwelch data", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchPwelchData();
  }, [dispatch, token, params.id]);

  // Define data for each channel
  const channels = [
    {
      x: heatmapData?.time_ch1,
      // y: heatmapData?.f_pwelch_ch1,
      z: heatmapData?.updated_Sxx_ch1,
    },
    {
      x: heatmapData?.time_ch2,
      // y: heatmapData?.f_pwelch_ch2,
      z: heatmapData?.updated_Sxx_ch2,
    },
  ];

  // Formik and Yup for validation
  const formik = useFormik({
    initialValues: {
      min: "",
      max: "",
    },
    validationSchema: Yup.object({
      min: Yup.number()
        .required("Min value is required")
        .typeError("Min value must be a number"),
      max: Yup.number()
        .required("Max value is required")
        .typeError("Max value must be a number"),
    }),
    onSubmit: (values) => {
      setShowPopup(false);
      setMinValue(values.min);
      setMaxValue(values.max);
      formik.resetForm();
    },
  });
  const handleAdjustClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    setPopupPosition({
      left: rect.right + 10,
      top: rect.top + window.scrollY,
    });
    setShowPopup(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
        formik.resetForm();
      }
    };
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <>
      <div className="fullscreen-container">
        <div className="header-stats">
          <div className="header-stat">
            <p>QEEG: </p>
            <div className="dropdown">
              <button
                className="btn green-btn2 btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                defaultValue="Spectrogram"
                style={{ fontWeight: "bold", fontSize: "14" }}
              >
                {qeeg}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => setQeeg("Spectrogram")}
                >
                  Spectrogram
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => setQeeg("Pwelch")}
                >
                  Pwelch
                </button>
              </div>
            </div>
          </div>

          <div className="header-stat">
            <p>Output: </p>
            <div className="dropdown">
              <button
                className="btn green-btn2 btn-sm dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                defaultValue="CH1"
                style={{ fontWeight: "bold", fontSize: "10" }}
              >
                {"CH1"}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                <button
                  className="dropdown-item"
                  type="button"
                  // onClick={() => setLffValue("N/A")}
                >
                  CH2
                </button>
                <button
                  className="dropdown-item"
                  type="button"
                  // onClick={() => setLffValue("1Hz")}
                >
                  Both
                </button>
              </div>
            </div>
            {qeeg === "Spectrogram" && (
              <div className="header-stat" style={{ position: "relative" }}>
                <p style={{ marginLeft: "20px" }}>
                  Colorbar:{" "}
                  <span
                    style={{ marginLeft: "2px", cursor: "pointer" }}
                    onClick={handleAdjustClick}
                    className="colorbar"
                  >
                    Adjust
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        {qeeg === "Spectrogram" ? (
          pwelchData?.data ? (
            channels.map((channel, index) => (
              <Plot
                key={index}
                data={[
                  {
                    x: channel.x,
                    // y: channel.y,
                    z: channel.z,
                    type: "heatmap",
                    colorscale: "Jet",
                    zmin: minValue,
                    zmax: maxValue,
                    colorbar: { title: "Heatmap Values" },
                  },
                ]}
                layout={{
                  autosize: true,
                  xaxis: {
                    title: {
                      text: "Time (s)",
                      font: { size: 16 },
                    },
                    tickfont: { size: 12 },
                  },
                  yaxis: {
                    title: {
                      text: "Frequency (Hz)",
                      font: { size: 16 },
                    },
                    tickfont: { size: 12 },
                  },
                  margin: { t: 20, b: 40, l: 50, r: 20 },
                }}
                useResizeHandler
                style={{ width: "100%", height: "200px" }}
              />
            ))
          ) : (
            <div className="text-center">Loading heatmap data...</div>
          )
        ) : (
          <PwelchGraph pwelchData={heatmapData ?? []} />
        )}
        {/* Pop-up for Colorbar Adjustment */}
        {showPopup && (
          <div
            className="popup"
            ref={popupRef}
            style={{ left: popupPosition.left, top: popupPosition.top }}
          >
            <form onSubmit={formik.handleSubmit}>
              <div>
                <label>
                  <p className="min-label">Min:</p>
                  <div className="input-err">
                    <input
                      name="min"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.min}
                    />

                    {formik.touched.min && formik.errors.min ? (
                      <div className="error">{formik.errors.min}</div>
                    ) : null}
                  </div>
                </label>
              </div>
              <div>
                <label>
                  Max:
                  <div className="input-err">
                    <input
                      name="max"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.max}
                    />
                    {formik.touched.max && formik.errors.max ? (
                      <div className="error">{formik.errors.max}</div>
                    ) : null}
                  </div>
                </label>
              </div>
              <button type="submit" className="btn-set">
                SET
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default HeatMap;
