// import "../../assets/css/style2.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listPatient } from "../../actions/patientActions";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// swiper bundle styles
import "swiper/swiper-bundle.min.css";
// swiper core styles
import "swiper/swiper.min.css";
// modules styles
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination, Scrollbar } from "swiper";

// import "../assets/css/pateint.css";
import "./patient-list-updated.css";
import ListHeader from "../list-record-components/list-header/ListHeader";
import ListView from "../list-record-components/list-view/ListView";
import GridView from "../list-record-components/grid-view/GridView";
import Loader from "../loader/Loader";
// install Swiper modules
SwiperCore.use([Pagination, Scrollbar]);

const KEYS = [
  "name",
  "age",
  "gender",
  // "patient#",
  // "last_visit_date",
  "clinical_condition",
];

export default function PatientListUpdated() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const patientList = useSelector((state) => state.patientList);
  const { error, loading, patients } = patientList;
  const [isEmpty, setIsEmpty] = useState(false);
  const [activeView, setActiveView] = useState("list");
  const [numberOfCols, setNumberOfCols] = useState(KEYS.length);
  const [keys, setKeys] = useState(KEYS);

  const [patientsData, setPatientsData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [assending, setAssending] = useState(true);

  const [show, setShow] = useState(false);
  const [patientDeleteId, setPatientDeleteId] = useState(null);

  const handleClose = () => setShow(false);

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch, token]);

  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  const handleActiveView = (view) => setActiveView(view);

  useEffect(() => {
    const transformedPatients = transformData(patients);
    setPatientsData(transformedPatients);
  }, [patients]);

  const handleConfirmDelete = async () => {
    const response = await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/patient/${patientDeleteId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (response.data.code === 401) {
      localStorage.clear();
      window.location.reload();
    } else {
      if (response.data.code === 200) {
        let newPatientList = patientsData.filter(
          (patient) => patient.id != patientDeleteId
        );
        setShow(false);
        setPatientsData(newPatientList);
        toast.error("Patient history deleted successfully");
      }
    }
  };

  const handleDelete = async (index) => {
    setPatientDeleteId(index);
    setShow(true);
  };

  const handleSort = () => {
    setAssending((prevState) => !prevState);
    let newPatients = [...patientsData];
    newPatients = newPatients.sort(function (a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (assending) {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
        return 0;
      }
    });
    setPatientsData(newPatients);
  };

  // to handle search filter by name
  useEffect(() => {
    if (searchField.length) {
      const filteredPatient = patientsData.filter((patient) => {
        return patient.name.toLocaleLowerCase().includes(searchField);
      });
      setPatientsData(filteredPatient);
    } else {
      setPatientsData(transformData(patients?.data?.results));
    }
  }, [searchField]);

  function transformData(inputData) {
    console.log("INPUT DATA: ", inputData);
    const fieldsToExtract = [
      "name",
      "age",
      "gender",
      // "id",
      // "last_visit_date",
      "clinical_condition",
    ];
    const transformedData = [];

    inputData &&
      inputData.forEach((item) => {
        const extractedFields = {};

        fieldsToExtract.forEach((field) => {
          extractedFields.id = item.id;
          if (field === "clinical_condition") {
            const conditionMap = {
              1: "seizure",
              2: "concussion",
              3: "stroke",
              4: "Normal",
              5: "Altered Mental Status",
              0: "0",
            };
            extractedFields[field] = conditionMap[item[field]] || "null";
          } else if (field === "gender") {
            const genderMap = {
              1: "M",
              2: "F",
            };
            extractedFields[field] = genderMap[item[field]] || "null";
          } else {
            extractedFields[field] =
              item[field] !== undefined ? item[field] : "null";
          }
        });

        transformedData.push(extractedFields);
      });
    return transformedData;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title id="contained-modal-title-vcenter">
            Patient Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this patient?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <section className="patient-list">
        <ListHeader
          title="Patient Overview"
          activeView={activeView}
          handleActiveView={handleActiveView}
          handleSearch={handleSearch}
        />
        {loading ? (
          <Loader />
        ) : !error ? (
          <>
            {activeView === "list" ? (
              <ListView
                len={numberOfCols}
                data={patientsData}
                keys={keys}
                handleDelete={handleDelete}
                handleSort={handleSort}
                forHistoryPage={true}
              />
            ) : (
              <GridView
                len={numberOfCols}
                data={patientsData}
                keys={keys}
                handleDelete={handleDelete}
              />
            )}
          </>
        ) : (
          <div
            style={{ textAlign: "center", fontWeight: "bold", color: "red" }}
          >
            No Data Exists
          </div>
        )}
      </section>
    </>
  );
}
