import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";

const CurrentInjury4 = () => {
  const history = useHistory();

  const initialValues = JSON.parse(
    localStorage.getItem("ConcussionForm10")
  ) || {
    sensitivity_to_noise: 0,
    feeling_slowed_down: 0,
    feeling_in_fog: 0,
    dont_feel_right: 0,
    memory_problems: 0,
    concentration_problems: 0,
    fatigue: 0,
    confusion: 0,
    drowsiness: 0,
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm10", JSON.stringify(values));
    history.push("/concussion-forms/add/11");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">10/12</span>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">Sensitivity to noise </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="sensitivity_to_noise">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("sensitivity_to_noise", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="sensitivity_to_noise"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Feeling slowed down</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="feeling_slowed_down">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("feeling_slowed_down", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="feeling_slowed_down"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Feeling like "in a fog" </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="feeling_in_fog">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("feeling_in_fog", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="feeling_in_fog"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">"Don't feel right" </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="dont_feel_right">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("dont_feel_right", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="dont_feel_right"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Difficulty concentrating </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="concentration_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("concentration_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="concentration_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Difficulty remembering </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="memory_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("memory_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="memory_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Fatigue or low energy </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="fatigue">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("fatigue", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="fatigue"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Confusion </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="confusion">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("confusion", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="confusion"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Drowsiness </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="drowsiness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("drowsiness", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="drowsiness"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/9")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury4;
