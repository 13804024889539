import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import Footer from "../Footer";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { getHospitalList } from "../../actions/doctorActions";
import { ReactComponent as ShowPasswordIcon } from "../../assets/img/svg/show-password.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/img/svg/hide-password.svg";
import { ReactComponent as LeftArrow } from "../../assets/img/svg/left-triangle.svg";
import { ReactComponent as IconTi } from "../../assets/img/svg/circle-tick.svg";
import { ReactComponent as IconTin } from "../../assets/img/svg/not-circle.svg";
import "../assets/css/login.css";

function Register() {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [registerAttempt, setRegisterAttempt] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [passwordType, setPasswordType] = useState("password");

  const hospitalList = useSelector((state) => state.hospitalList);

  const togglePasswordType = () =>
    setPasswordType((prevState) =>
      prevState === "password" ? "text" : "password"
    );
  const onPasswordChange = (e) => {
    let passwordStrength = checkPasswordStrength(e.target.value);
    setPasswordStrength(passwordStrength);
  };
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [numericCase, setNumericCase] = useState(false);
  const [specialCase, setSpecialCase] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const checkPasswordStrength = (password) => {
    // Define regex patterns for different character types
    var patterns = {
      lowerCase: /[a-z]/,
      upperCase: /[A-Z]/,
      numbers: /[0-9]/,
      specialChars: /[^A-Za-z0-9\s]/,
    };

    // Check if password meets the criteria
    var hasLowerCase = patterns.lowerCase.test(password);
    var hasUpperCase = patterns.upperCase.test(password);
    var hasNumbers = patterns.numbers.test(password);
    var hasSpecialChars = patterns.specialChars.test(password);

    setLowerCase(hasLowerCase);
    setUpperCase(hasUpperCase);
    setNumericCase(hasNumbers);
    setSpecialCase(hasSpecialChars);

    // Calculate strength based on criteria met
    var strength = 0;
    strength += hasLowerCase ? 1 : 0;
    strength += hasUpperCase ? 1 : 0;
    strength += hasNumbers ? 1 : 0;
    strength += hasSpecialChars ? 1 : 0;

    // Calculate password length bonus
    var lengthBonus = Math.min(2, password.replace(/\s/g, "").length / 8);
    strength += lengthBonus;

    // Return strength as a percentage
    console.log(Math.min(Math.round((strength / 6) * 100), 100));
    return Math.min(Math.round((strength / 6) * 100), 100);
  };

  useEffect(() => {
    dispatch(getHospitalList());
  }, [dispatch]);

  const { hospitals = [] } = hospitalList;

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const [email, password, firstName, lastName, medicalId, hospitalId] =
        e.target;
      if (password.value.length < 8 || passwordStrength < 80) {
        toast.error(
          "Your password is too weak. Please choose a stronger password."
        );
        return;
      }

      const registerCredentials = {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        medicalId: medicalId.value,
        hospitalId: hospitalId.value,
        confirmPassword: password.value,
      };

      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/register`, registerCredentials)
        .then((res) => {
          setLoading(false);
          if (res.data.status === true) {
            toast.success("Registered successfully");
            history.push("/login");
          } else {
            toast.error("Failed to register");
            setRegisterAttempt(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setRegisterAttempt(false);
          toast.error("Failed to register");
        });
    }
    setValidated(true);
  };

  return (
    <div>
      {" "}
      <>
        <section className="login-main">
          <div className="container h-100">
            <div className="row justify-content-center p-0 m-0 h-100">
              <div className="col-xxl-5 col-lg-5 col-md-12 col-sm-12 py-5 h-100">
                <div className="content">
                  <div className="div-image">
                    <a href="/">
                      <img
                        src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                        className="logo-image-class"
                        alt="company logo"
                      />
                    </a>
                  </div>
                  <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                    <div className="card-title">
                      <h2 className="p-3 pb-1">Create your Account</h2>
                    </div>
                    <div className="card-body">
                      <form
                        className={`needs-validation ${
                          validated ? "was-validated" : ""
                        }`}
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        {/* <form onSubmit={handleSubmit} className="was-validated  needs-validation" novalidate> */}
                        <div className="mb-4">
                          <label
                            htmlFor="email"
                            className="form-label login-form-label"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control login-form-input"
                            id="email"
                            required
                          />
                          {/* <div className="valid-feedback">Valid.</div> */}
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        </div>
                        <div className="mb-4 password-field">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="password"
                              className="form-label login-form-label"
                            >
                              Password
                            </label>
                          </div>
                          <input
                            type={passwordType}
                            className="form-control login-form-input"
                            id="password"
                            required
                            onChange={(e) => onPasswordChange(e)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                          {passwordType === "password" ? (
                            <HidePasswordIcon
                              onClick={togglePasswordType}
                              className="eye-icon"
                            />
                          ) : (
                            <ShowPasswordIcon
                              onClick={togglePasswordType}
                              className="eye-icon"
                            />
                          )}

                          {isFocused && (
                            <div className="password-checker">
                              <p className="margins-b">Password Requirements</p>
                              <div className="check-list">
                                <div
                                  className={`check ${
                                    upperCase ? "line-through" : ""
                                  }`}
                                >
                                  {upperCase ? <IconTi /> : <IconTin />}
                                  One Upper Case
                                </div>
                                <div
                                  className={`check ${
                                    lowerCase ? "line-through" : ""
                                  }`}
                                >
                                  {lowerCase ? <IconTi /> : <IconTin />}
                                  One Lower Case
                                </div>
                                <div
                                  className={`check ${
                                    numericCase ? "line-through" : ""
                                  }`}
                                >
                                  {numericCase ? <IconTi /> : <IconTin />}
                                  One Numeric
                                </div>
                                <div
                                  className={`check ${
                                    specialCase ? "line-through" : ""
                                  }`}
                                >
                                  {specialCase ? <IconTi /> : <IconTin />}
                                  One Special Character
                                </div>
                              </div>
                              <div className="strength-container">
                                <div className="instructions">
                                  Strength: &nbsp;
                                  {passwordStrength <= 50 && (
                                    <span className="weak">Weak</span>
                                  )}
                                  {passwordStrength > 50 &&
                                    passwordStrength <= 80 && (
                                      <span className="medium">Medium</span>
                                    )}
                                  {passwordStrength > 80 && (
                                    <span className="strong">Strong</span>
                                  )}
                                </div>
                                <div className="strengths">
                                  <div
                                    className={`strength ${
                                      (passwordStrength <= 50 ||
                                        passwordStrength > 50) &&
                                      "weak-bg"
                                    }`}
                                  />
                                  <div
                                    className={`strength ${
                                      ((passwordStrength > 50 &&
                                        passwordStrength <= 80) ||
                                        passwordStrength > 80) &&
                                      "medium-bg"
                                    }`}
                                  />
                                  <div
                                    className={`strength ${
                                      passwordStrength > 80 && "strong-bg"
                                    }`}
                                  />
                                </div>
                              </div>
                              <LeftArrow className="arrow" />
                            </div>
                          )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="mb-4 res-m">
                            <label
                              htmlFor="firstname"
                              className="form-label login-form-label"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control login-form-input"
                              id="firstname"
                              required
                            />
                            {/* <div className="valid-feedback">Valid.</div> */}
                            <div className="invalid-feedback">
                              Please fill out this field.
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="lastname"
                              className="form-label login-form-label"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control login-form-input"
                              id="lastname"
                              required
                            />
                            {/* <div className="valid-feedback">Valid.</div> */}
                            <div className="invalid-feedback">
                              Please fill out this field.
                            </div>
                          </div>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="medicalid"
                            className="form-label login-form-label"
                          >
                            Medical ID
                          </label>
                          <input
                            type="text"
                            className="form-control login-form-input"
                            id="medicalid"
                            required
                          />
                          {/* <div className="valid-feedback">Valid.</div> */}
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            htmlFor="hospital"
                            className="form-label login-form-label"
                          >
                            Hospital
                          </label>
                          <select
                            id="hospital"
                            className="form-control login-form-input select-options"
                            required
                          >
                            <option value="" disabled selected>
                              Select a hospital
                            </option>
                            {hospitals?.map((hospital) => (
                              <option key={hospital.id} value={hospital.id}>
                                {hospital.name}
                              </option>
                            ))}
                          </select>
                          <div className="invalid-feedback">
                            Please select a hospital from the list.
                          </div>
                        </div>

                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn text-light main-bg btn-style"
                            disabled={loading}
                          >
                            <b>CREATE ACCOUNT</b>
                          </button>
                          <p className="text-center my-4 bottom-text">
                            Already have an account?{" "}
                            <Link to="/login">Sign In</Link>
                          </p>
                        </div>

                        {!registerAttempt && (
                          <div style={{ paddingTop: "15px" }}>
                            <Alert variant="danger">
                              <p>Something went wrong!</p>
                            </Alert>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    </div>
  );
}

export default Register;
