import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const PERFORMANCE_CHOICES = [
  { value: "ABV", label: "Above Average" },
  { value: "AVG", label: "Average" },
  { value: "BLW", label: "Below Average" },
];

const SocialHistory = () => {
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    // occupation: Yup.string().required("Occupation is required"),
    homework_hours_nightly: Yup.number().typeError(
      "Homework Hours must be a number"
    ),
    // .required("Homework Hours is required"),
    hours_per_night_since_injury: Yup.number().typeError(
      "Sleep Hours must be a number"
    ),
    // .required("Sleep Hours is required"),
    screen_time_hours_per_day: Yup.number().typeError(
      "Screen Time must be a number"
    ),
    // .required("Screen Time is required"),
  });

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm3")) || {
    occupation: "",
    performance_in_school: "ABV",
    homework_hours_nightly: "",
    hours_per_night_since_injury: "",
    screen_time_hours_per_day: "",
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm3", JSON.stringify(values));
    history.push("/concussion-forms/add/4");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Social History</span>
        <span className="form-progress">3/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">occupation</label>
                <Field name="occupation" className="field-input" />
                <ErrorMessage
                  name="occupation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Performance in school?</label>
                <div role="group" className="tabs-group">
                  {PERFORMANCE_CHOICES.map((choice) => (
                    <Field name="performance_in_school" key={choice.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === choice.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "performance_in_school",
                              choice.value
                            )
                          }
                        >
                          {choice.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="performance_in_school"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  How many hours of homework nightly?
                </label>
                <Field name="homework_hours_nightly" className="field-input" />
                <ErrorMessage
                  name="homework_hours_nightly"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Hours of sleep per night since injury?
                </label>
                <Field
                  name="hours_per_night_since_injury"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="hours_per_night_since_injury"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Hours of screen time per day?
                </label>
                <Field
                  name="screen_time_hours_per_day"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="screen_time_hours_per_day"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/2")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SocialHistory;
