import React, { useState, useRef, useEffect } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";
import { getPatientVisitDetails } from "../../actions/patientActions";
import {
  getEegCompareGraphs,
  getEegGraphFilter,
} from "../../actions/graphActions";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";
import pauseIcon from "../../assets/img/svg/pause-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import BackIcon from "../../assets/img/svg/back-icon.svg";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { formatName, formatDate, formatDateTime } from "../../helper";
import LinearWithValueLabel from "../loader/LinearLoaderLablel";
// import "./recording2.css";
import CircularLabelLoader from "../loader/CircularLabelLoader";

const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
  4: "Normal",
  5: "Altered Mental Status",
  0: "0",
};
const genderMap = {
  1: "M",
  2: "F",
};

Chart.register(zoomPlugin);

const CompareVisits = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [lffValue, setLffValue] = useState("Select");
  const [hffValue, setHffValue] = useState("Select");
  const [notchValue, setNotchValue] = useState("Select");
  const [alert, setAlert] = useState();

  const [charts, setCharts] = useState([]);
  const chartRefs = useRef([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [dataTime, setDataTime] = useState([]);

  // const [location, setLocation] = useState(null);

  const [eegData, setEegData] = useState(null);
  const [patientData, setPatientData] = useState();

  const [filteredEegData, setFilteredEegData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);
  const token = useSelector((state) => state.loginData.token);
  const [requestStatus, setRequestStatus] = useState(false);
  const [relatedIds, setRelatedIds] = useState([]);

  const { eegCompareData } = useSelector((state) => state.eegCompareGraphs);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(1);

  const searchParams = new URLSearchParams(window.location.search);
  const ids = searchParams.getAll("id").map(Number);

  const updateGraph = (dataCount) => {
    const location_visit0 = (
      eegCompareData?.data?.visit_0[0]?.patient_data?.location || "N/A"
    ).split("-");
    const location_visit1 = (
      eegCompareData?.data?.visit_1[0]?.patient_data?.location || "N/A"
    ).split("-");

    const datasets = [
      {
        data: eegCompareData?.data?.visit_0[0],
        channelName: "CH1_Voltage",
        display: false,
        location: location_visit0[0],
        filteredDataIndex: 0,
      },
      {
        data: eegCompareData?.data?.visit_0[0],
        channelName: "CH2_Voltage",
        display: false,
        location: location_visit0[1],
        filteredDataIndex: 0,
      },
      {
        data: eegCompareData?.data?.visit_1[0],
        channelName: "CH1_Voltage",
        display: false,
        location: location_visit1[0],
        filteredDataIndex: 1,
      },
      {
        data: eegCompareData?.data?.visit_1[0],
        channelName: "CH2_Voltage",
        display: true,
        location: location_visit1[1],
        filteredDataIndex: 1,
      },
    ];

    const visitCharts = datasets.map((item, i) => {
      const canvasRef = chartRefs.current[i];
      if (canvasRef?.chart) {
        canvasRef.chart.destroy();
        canvasRef.chart = null;
      }
      return createGraph(
        chartRefs.current[i],
        item.data,
        item.channelName,
        item.location,
        dataCount,
        item.display,
        item.filteredDataIndex
      );
    });

    setCharts(visitCharts);
  };

  const handleGraphButton = (type) => {
    let newCount;
    switch (type) {
      case "fast-backward":
        newCount = 0;
        stopPlaying();
        break;
      case "backward":
        newCount = Math.max(0, dataCount - 1);
        stopPlaying();
        break;
      case "forward":
        newCount = Math.min(dataLength - 1, dataCount + 1);
        stopPlaying();
        break;
      case "fast-forward":
        newCount = dataLength - 1;
        stopPlaying();
        break;
      case "play":
        if (!isPlaying) {
          if (newCount === dataLength - 1) {
            setDataCount(0);
          }
          startPlaying();
        }
        return;
      case "pause":
        stopPlaying();
        return;
      default:
        return;
    }

    setDataCount(newCount);
    updateGraph(newCount);
  };

  const startPlaying = () => {
    setIsPlaying(true);
    intervalRef.current = setInterval(() => {
      setDataCount((prevCount) => {
        if (prevCount < dataLength - 1) {
          const newCount = prevCount + 1;
          if (chartRefs.current) {
            chartRefs.current.forEach((canvasRef) => {
              if (canvasRef && canvasRef.chart) {
                canvasRef.chart.destroy();
              }
            });
          }
          updateGraph(newCount);
          return newCount;
        } else {
          stopPlaying();
          return -1;
        }
      });
    }, 500);
  };

  const stopPlaying = () => {
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const createGraph = (
    canvasRef,
    data,
    label,
    location,
    dataCount,
    display,
    filteredDataIndex
  ) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }
    const canvasElement = canvasRef?.getContext?.("2d")?.canvas;
    if (canvasRef.chart) {
      canvasElement?.removeEventListener(
        "dblclick",
        canvasRef.handleDoubleClick
      );
      canvasRef.chart.destroy();
      canvasRef.chart = null;
    }

    const handleDoubleClick = () => {
      canvasRef.chart.options.scales.y.min = null;
      canvasRef.chart.options.scales.y.max = null;
      canvasRef.chart.resetZoom();
      canvasRef.chart.update();
    };

    const chart = new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.length === 0 ? [] : dataTime[dataCount],
        datasets: [
          {
            data:
              data.length === 0
                ? []
                : isFilter
                ? filteredEegData[filteredDataIndex][label][dataCount]
                : data[label][dataCount],
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              display: true,
            },
            display: true,
            ticks: {
              display: display,
              type: "linear",
              stepSize: 1,
              maxTicksLimit: 10,
              beginAtZero: true,
              align: "center",
              maxRotation: 0,
              minRotation: 0,
              padding: 0,
              font: {
                size: 13,
              },
            },
            // title: {
            //   display: display,
            //   text: "Time (seconds)",
            //   padding: 0,
            //   font: {
            //     size: 14,
            //   },
            // },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            title: {
              display: true,
              text: [location],
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              wheel: {
                enabled: true,
                mode: "x",
              },
            },
          },
        },

        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
      },
    });

    if (canvasElement) {
      canvasElement.addEventListener("dblclick", handleDoubleClick);
      canvasRef.handleDoubleClick = handleDoubleClick;
    }

    canvasRef.chart = chart;
    return chart;
  };

  useEffect(() => {
    const fetchEegData = async () => {
      try {
        setLoading(true);
        await dispatch(getEegCompareGraphs(token, ids));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAlert(error.response);
        toast.error("Error fetching data", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchEegData();
  }, [dispatch, token]);

  useEffect(() => {
    if (!loading && eegCompareData) {
      setPatientData(eegCompareData?.data?.visit_0[0]?.patient_data);
      const visit1 = eegCompareData?.data?.visit_0[0];
      const visit2 = eegCompareData?.data?.visit_1[0];
      setDataLength(Math.max(visit1?.length?.length, visit2?.length?.length));
      const maxTimeLength =
        visit1?.length?.length > visit2?.length?.length
          ? visit1?.length
          : visit2?.length;
      setDataTime(maxTimeLength);
      setEegData(eegCompareData?.data);

      const location_visit0 = (visit1?.patient_data?.location || "N/A").split(
        "-"
      );

      const location_visit1 = (visit2?.patient_data?.location || "N/A").split(
        "-"
      );

      // Destroy old charts before creating new ones
      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });

      const datasets = [
        {
          data: visit1,
          channelName: "CH1_Voltage",
          display: false,
          location: location_visit0[0],
        },
        {
          data: visit1,
          channelName: "CH2_Voltage",
          display: false,
          location: location_visit0[1],
        },
        {
          data: visit2,
          channelName: "CH1_Voltage",
          display: false,
          location: location_visit1[0],
        },
        {
          data: visit2,
          channelName: "CH2_Voltage",
          display: true,
          location: location_visit1[1],
        },
      ];

      const visitCharts = datasets.map((item, i) =>
        createGraph(
          chartRefs.current[i],
          item.data,
          item.channelName,
          item.location,
          dataCount,
          item.display
        )
      );

      setCharts(visitCharts);
    }
    // Cleanup on unmount
    return () => {
      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });
    };
  }, [loading, eegCompareData, dataTime]);

  // Apply filters
  const applyFilters = async () => {
    setProgress(0);
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 1
        );
      }, 1000);
      return () => clearInterval(interval);
    };

    simulateProgress();

    if ([lffValue, hffValue, notchValue].includes("Select")) {
      toast.error("Please select valid values for all filters.");
      return;
    }
    setIsFilter(true);
    setLoadingFilter(true);
    const filterData = await dispatch(
      getEegGraphFilter(
        token,
        eegCompareData?.data?.visit_detail_ids,
        lffValue,
        hffValue,
        notchValue
      )
    );
    setLoadingFilter(false);

    if (filterData) {
      setFilteredEegData(filterData);
      setDataCount(0);
      setProgress(100);
    } else {
      toast.error("Error applying filters");
      setProgress(100);
      setIsFilter(false);
    }
  };

  const resetFilter = () => {
    setDataCount(0);
    setIsFilter(false);
    setFilteredEegData(null);
    setLffValue("Select");
    setHffValue("Select");
    setNotchValue("Select");
  };

  useEffect(() => {
    if (isFilter && filteredEegData && !loadingFilter) {
      updateGraph(0);
    }
  }, [isFilter, filteredEegData, loadingFilter]);

  return (
    <div>
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>No data was found for this patient visit</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className="recording2">
          <div className="recording-graph-panel">
            <div className="info-container">
              <div className="personal-info">
                <div className="btn-back" onClick={handleBack}>
                  <span className="back-info-btn">
                    <img src={BackIcon} alt="back-icon" />
                  </span>
                  <div className="back-info-btn" onClick={handleBack}>
                    Back
                  </div>
                </div>

                <h1>{patientData?.id_number ?? "N/A"}</h1>
                <div className="info-wrapper">
                  <div className="info">
                    <p>Age / Gender: </p>
                    <p>
                      {patientData?.age || "N/A"}{" "}
                      {genderMap[patientData?.gender] || "N/A"}
                    </p>
                  </div>
                  {/* <div className="info">
                    <p>Patient#: </p>
                    <p>{patientData?.id_number || "N/A"}</p>
                  </div> */}
                  <div className="info">
                    <p>Recording Date: </p>
                    <p>
                      {patientData?.timestamp
                        ? formatDate(patientData?.timestamp)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="info">
                    <p>Condition: </p>
                    <p>
                      {conditionMap[patientData?.clinical_condition] || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="btns-container">
                <button className="button btn-invert-rec">Add Notes</button>
                <button className="button btn-default-rec">Share Report</button>
              </div> */}
            </div>

            <div className="eeg-graph-panel">
              {loading || loadingFilter ? (
                <div className="linear-loader">
                  {isFilter ? (
                    <CircularLabelLoader value={progress} />
                  ) : (
                    <LinearWithValueLabel increment={1} />
                  )}
                </div>
              ) : (
                <>
                  <div className="eeg-header">
                    <div className="header-stats">
                      <div className="header-stat">
                        <p>
                          Display: <span>10s</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>
                          Scale: <span>10uv/mm</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>LFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="1Hz"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {lffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("1Hz")}
                            >
                              1Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("5Hz")}
                            >
                              5Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("15Hz")}
                            >
                              15Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("20Hz")}
                            >
                              20Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>HFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {hffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("15Hz")}
                            >
                              15 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("30Hz")}
                            >
                              30 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("40Hz")}
                            >
                              40 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("70Hz")}
                            >
                              70 Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>Notch: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn btn-sm green-btn2 dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {notchValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("N/A");
                              }}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("50Hz");
                              }}
                            >
                              50 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("60Hz");
                              }}
                            >
                              60 Hz
                            </button>
                          </div>
                        </div>
                        <button
                          className="btn green-btn32 btn-sm"
                          type="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: "6",
                          }}
                          disabled={requestStatus}
                          onClick={() => {
                            applyFilters(lffValue, hffValue, notchValue);
                          }}
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="location-btns">
                      {/* <button
                        className="btn loc-btn"
                        type="button"
                        onClick={() => {
                          if (currentIndex > 0) {
                            setCurrentIndex((prevIndex) =>
                              prevIndex > 0 ? prevIndex - 1 : 0
                            );
                            resetFilter();
                          }
                        }}
                        disabled={currentIndex === 0}
                      >
                        <ArrowBackIos
                          style={{ fontSize: 12, marginRight: 4 }}
                        />
                        <span className="loc-name">Back</span>
                      </button> */}
                      <span className="loc-name">
                        {/* {visitDetails?.data?.[currentIndex]?.location ?? "N/A"} */}
                        {patientData?.location}
                      </span>
                      {/* <button
                        className="btn loc-btn"
                        type="button"
                        onClick={() => {
                          if (
                            currentIndex < relatedIds.length - 1 ||
                            currentIndex <= 4
                          ) {
                            setCurrentIndex((prevIndex) => prevIndex + 1);
                            resetFilter();
                          }
                        }}
                        disabled={
                          currentIndex === relatedIds.length - 1 ||
                          currentIndex > 4
                        }
                      >
                        <span className="loc-name">Next</span>
                        <ArrowForwardIos
                          style={{ fontSize: 12, marginLeft: 4 }}
                        />
                      </button> */}
                    </div>
                  </div>
                  <div>
                    <span className="fw-bold rec-data">
                      {formatDateTime(
                        eegCompareData?.data?.visit_0[0]?.patient_data
                          ?.timestamp
                      )}
                    </span>
                    <div className="graph-box-compare">
                      {[0, 1].map((chart, index) => (
                        <div key={index} className="canvas-wrapper">
                          <canvas
                            ref={(el) => (chartRefs.current[chart] = el)}
                            height={120}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-2">
                    <span className="fw-bold rec-data1">
                      {formatDateTime(
                        eegCompareData?.data?.visit_1[0]?.patient_data
                          ?.timestamp
                      )}
                    </span>
                    <div className="graph-box-compare">
                      {[2, 3].map((chart, index) => (
                        <div key={index} className="canvas-wrapper">
                          <canvas
                            ref={(el) => (chartRefs.current[chart] = el)}
                            height={120}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="eeg-graph-controls">
                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-backward")}
                    >
                      <img src={fastBackwardIcon} alt="fast backward" />
                    </button>

                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("backward")}
                    >
                      <img src={backwardIcon} alt="backward" />
                    </button>

                    <button
                      className="control-button"
                      onClick={() =>
                        handleGraphButton(isPlaying ? "pause" : "play")
                      }
                    >
                      <img
                        src={isPlaying ? pauseIcon : playIcon}
                        alt={isPlaying ? "pause" : "play"}
                      />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("forward")}
                    >
                      <img src={forwardIcon} alt="forward" />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-forward")}
                    >
                      <img src={fastForwardIcon} alt="fast forward" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompareVisits;
