import React, { useEffect, useState, useRef } from "react";
import { formatDate, formatTime, formatName } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { getPatientVisitsDetail } from "../../actions/doctorActions";
import Table from "../react-table-component/ReactTable";
import { ArrowDownward, ArrowForwardIos, MoreVert } from "@mui/icons-material";
import BackIcon from "../../assets/img/svg/back-icon.svg";
import { PatientVisitDetailDeleteConfirmModal } from "./PatientVisitDetailDeleteConfirmModal";
import { toast } from "react-toastify";

const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
  4: "Normal",
  5: "Altered Mental Status",
  0: "0",
};
const genderMap = {
  1: "M",
  2: "F",
};

const SETTINGS = {
  1: "Clinic",
  2: "ED",
  3: "ICU",
};

const KEY_NOTES = {
  1: "Baseline",
  2: "Follow-up",
  3: "Normal",
  4: "PDR",
  5: "SSVEP",
  6: "P300",
  7: "Ictal",
  8: "Pre-ictal",
  9: "Sine Wave",
  10: "Square Wave",
  11: "Triangle Wave",
  12: "Test Dataset",
  13: "OMIT",
};

export default function PatientVisitsDetail() {
  const params = useParams();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const token = useSelector((state) => state.loginData.token);
  const patientVisits = useSelector((state) => state.patientVisitsDetail);
  const { error, loading, visitsDetail } = patientVisits;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 5
        );
      }, 500);
      return () => clearInterval(interval);
    };

    simulateProgress();

    dispatch(getPatientVisitsDetail(token, params.id))
      .then(() => {
        setProgress(100);
      })
      .catch(() => {
        setProgress(100);
        toast.error("No Visit Detail Found for this Patient");
        history.push("/dashboard");
      });
  }, [dispatch, token]);

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(rowIndex)) {
        const newExpandedRows = prevExpandedRows.filter(
          (index) => index !== rowIndex
        );

        return newExpandedRows;
      } else {
        // Add the row index if it's not expanded
        const newExpandedRows = [...prevExpandedRows, rowIndex];
        return newExpandedRows;
      }
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Date of Visit",
        accessor: "timestamp",
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div
              onClick={() => toggleRowExpansion(row.index)}
              style={{ cursor: "pointer" }}
            >
              {isExpanded ? (
                <ArrowDownward style={{ fontSize: 12, marginRight: 4 }} />
              ) : (
                <ArrowForwardIos style={{ fontSize: 12, marginRight: 4 }} />
              )}
              {formatDate(row.original.timestamp)}
            </div>
          );
        },
      },
      {
        Header: "Time",
        sortable: true,
        accessor: "time",
        Cell: ({ row }) => {
          return <div>{formatTime(row.values.timestamp)}</div>;
        },
      },
      {
        Header: "# of locs",
        sortable: true,
        accessor: "locations",
        Cell: ({ row }) => {
          return <div>{row.original.details.length}</div>;
        },
      },
      {
        Header: "Setting",
        accessor: "clinical_setting",
        Cell: () => {
          return <div>{SETTINGS[visitsDetail.patient.clinical_setting]}</div>;
        },
      },
      {
        Header: "Key Notes",
        accessor: "clinical_keynote",
        Cell: () => {
          return <div>{KEY_NOTES[visitsDetail.patient.clinical_keynote]}</div>;
        },
      },
      {
        Header: "",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const isExpanded = expandedRows.includes(row.index);
          return (
            <div>
              {isExpanded ? (
                <div className="btns">
                  <Link
                    to={`/recording/${row?.original?.details[0]?.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="action-button-expanded">
                      VIEW VISIT
                    </button>
                  </Link>
                  {/* <button className="action-button-expanded delete">
                    DELETE VISIT
                  </button> */}
                </div>
              ) : (
                <div className="btns">
                  <Link
                    to={`/recording/${row?.original?.details[0]?.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <button className="action-button"> VIEW VISIT</button>
                  </Link>
                  {/* <button className="action-button delete">
                    {" "}
                    DELETE VISIT
                  </button> */}
                </div>
              )}
            </div>
          );
        },
      },
    ],
    [expandedRows, toggleRowExpansion]
  );

  const handleSelectedRowsChange = (selectedIds) => {
    setSelectedRows(selectedIds);
  };
  const handleBack = () => {
    window.history.back();
  };

  const history = useHistory();
  const handleCompareVisits = () => {
    if (selectedRows.length === 2) {
      const queryString = selectedRows.map((id) => `id=${id}`).join("&");
      history.push(`/compare-visits?${queryString}`);
    } else {
      alert("Please select exactly 2 visits to compare.");
    }
  };

  const handleEditPatient = () => {
    const patientId = visitsDetail?.visits[0]?.patient;
    if (patientId) {
      history.push(`/edit-patient/${patientId}`);
    } else {
      alert("Patient not found.");
    }
  };
  const handleDeleteVisitDetails = (id) => {
    setDetailId(id);
    setShowDeleteDialog(true);
  };

  useEffect(() => {
    if (isDeleted) setExpandedRows([]);
  }, [isDeleted]);

  return (
    <div className="page-container">
      <PatientVisitDetailDeleteConfirmModal
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false);
        }}
        id={detailId}
        token={token}
        visitDetailID={params.id}
        isDeleted={() => setIsDeleted(true)}
      />
      <div className="recording2">
        <div className="recording-graph-panel">
          <div className="info-container">
            <div className="personal-info">
              <div className="btn-back">
                <span className="back-info-btn">
                  <img src={BackIcon} alt="back-icon" onClick={handleBack} />
                </span>
                <div className="back-info-btn" onClick={handleBack}>
                  Back
                </div>
              </div>

              <h1>{visitsDetail?.patient?.patient_id ?? "N/A"}</h1>
              <div className="info-wrapper">
                <div className="info">
                  <p>Age/Gender: </p>
                  <p>
                    {visitsDetail?.patient?.age} /{" "}
                    {genderMap[visitsDetail?.patient?.gender] || "Unknown"}
                  </p>
                </div>

                <div className="info">
                  <p>Last Visit: </p>
                  <p>
                    {formatDate(
                      visitsDetail?.visits[visitsDetail?.visits?.length - 1]
                        .timestamp
                    )}
                  </p>
                </div>

                <div className="info">
                  <p>Condition: </p>
                  <p>
                    {conditionMap[visitsDetail?.patient?.condition] ||
                      "Unknown"}
                  </p>
                </div>
              </div>
            </div>
            <div className="btns-container">
              <button
                className="button btn-default"
                onClick={handleEditPatient}
              >
                Edit Patient
              </button>
              <button
                className="button btn-default"
                onClick={handleCompareVisits}
              >
                Compare visit
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="patient-list">
        <Table
          columns={columns}
          data={visitsDetail?.visits ?? []}
          // params={searchField}
          // fetchData={fetchData}
          // pageCount={patients?.data?.pagination?.total_pages ?? 1}
          loading={loading}
          tableOf="PatientVisitsDetail"
          progress={progress}
          onSelectedRowsChange={handleSelectedRowsChange}
          handleDeleteVisitDetails={handleDeleteVisitDetails}
          patientVisitsDetailValue={visitsDetail?.patient?.clinical_keynote}
        />
      </section>
    </div>
  );
}
