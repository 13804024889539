import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";

const ConcussionHistory = () => {
  const validationSchema = Yup.object().shape({
    past_concussions_count: Yup.number()
      .nullable()
      .typeError("Past Diagnosed must be a number"),
  });

  const history = useHistory();

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm2")) || {
    past_concussions_count: null,
    most_recent_concussion_date: null,
    recovery_time: "",
    primary_symptoms: "",
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm2", JSON.stringify(values));
    history.push("/concussion-forms/add/3");

    // alert(JSON.stringify(values, null, 2));
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Concussion History</span>
        <span className="form-progress">2/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  How many past diagnosed concussion?
                </label>
                <Field name="past_concussions_count" className="field-input" />
                <ErrorMessage
                  name="past_concussions_count"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Most recent concussion?</label>
                <Field
                  name="most_recent_concussion_date"
                  className="field-input"
                  type="date"
                />
                <ErrorMessage
                  name="most_recent_concussion_date"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Recovery time from most recent concussion?
                </label>
                <Field
                  name="recovery_time"
                  className="field-input"
                  placeholder="2 weeks"
                />
                <ErrorMessage
                  name="recovery_time"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Primary symptoms?</label>

                <Field
                  name="primary_symptoms"
                  as="textarea"
                  className="field-input symptoms"
                />
                <ErrorMessage
                  name="primary_symptoms"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/1")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConcussionHistory;
