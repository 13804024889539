import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,
  PATIENT_VISITS_DETAIL_REQUEST,
  PATIENT_VISITS_DETAIL_SUCCESS,
  PATIENT_VISITS_DETAIL_FAIL,
  HOSPITAL_LIST_REQUEST,
  HOSPITAL_LIST_SUCCESS,
  HOSPITAL_LIST_FAIL,
} from "../constants/doctorConstants";

export const doctorListReducer = (state = { doctors: [] }, action) => {
  switch (action.type) {
    case DOCTOR_LIST_REQUEST:
      return { loading: true, doctors: [] };

    case DOCTOR_LIST_SUCCESS:
      return {
        loading: false,
        doctors: action.payload,
      };

    case DOCTOR_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const patientVisitsDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_VISITS_DETAIL_REQUEST:
      return { loading: true };

    case PATIENT_VISITS_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        visitsDetail: action.payload,
      };

    case PATIENT_VISITS_DETAIL_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};

export const hospitalListReducer = (state = {}, action) => {
  switch (action.type) {
    case HOSPITAL_LIST_REQUEST:
      return { loading: true };

    case HOSPITAL_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        hospitals: action.payload,
      };

    case HOSPITAL_LIST_FAIL:
      return { loading: true, error: action.payload };

    default:
      return state;
  }
};
