export const DOCTOR_LIST_REQUEST = "DOCTOR_LIST_REQUEST";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

export const PATIENT_VISITS_DETAIL_REQUEST = "PATIENT_VISITS_DETAIL_REQUEST";
export const PATIENT_VISITS_DETAIL_SUCCESS = "PATIENT_VISITS_DETAIL_SUCCESS";
export const PATIENT_VISITS_DETAIL_FAIL = "PATIENT_VISITS_DETAIL_FAIL";

export const HOSPITAL_LIST_REQUEST = "HOSPITAL_LIST_REQUEST";
export const HOSPITAL_LIST_FAIL = "HOSPITAL_LIST_FAIL";
export const HOSPITAL_LIST_SUCCESS = "HOSPITAL_LIST_SUCCESS";
