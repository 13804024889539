import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import { createConcussionForm } from "../../../actions/patientActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
const CurrentInjury6 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const [totalSymptoms, setTotalSymptoms] = useState(0);
  const [severityScore, setSeverityScore] = useState(0);

  const initialValues = {
    total_symptoms: totalSymptoms,
    symptom_severity_score: severityScore,
    worse_with_physical_activity: "N",
    worse_with_mental_activity: "N",
    percent_feeling_normal: 0,
    symptoms_explanation: "",
  };

  // calculate symptoms count and severity score
  useEffect(() => {
    const allFormKeys = [
      "ConcussionForm9",
      "ConcussionForm10",
      "ConcussionForm11",
    ];
    let totalSymptomsCount = 0;
    let totalSeverityScore = 0;

    allFormKeys.forEach((key) => {
      const formData = JSON.parse(localStorage.getItem(key)) || {};
      Object.values(formData).forEach((value) => {
        if (value > 0) {
          // Assuming symptom score is numeric
          totalSymptomsCount++;
          totalSeverityScore += value;
        }
      });
    });

    setTotalSymptoms(totalSymptomsCount);
    setSeverityScore(totalSeverityScore);
  }, []);

  const handleSubmit = (values) => {
    const allFormKeys = Object.keys(localStorage).filter((key) =>
      key.startsWith("ConcussionForm")
    );

    const aggregatedData = allFormKeys.reduce((acc, key) => {
      const formData = JSON.parse(localStorage.getItem(key)) || {};
      return { ...acc, ...formData, ...values };
    }, {});

    dispatch(createConcussionForm(token, aggregatedData))
      .then(() => {
        toast.success("Concussion Form submitted successfully");
        allFormKeys.forEach((key) => localStorage.removeItem(key));
        history.push("/concussion-forms");
      })
      .catch((error) => {
        toast.error("Error! " + error.message);
      });
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">12/12</span>
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Total Number of Symptoms:{" "}
                  <span className="score-label">
                    {totalSymptoms}/22&nbsp;&nbsp; (
                    {((totalSymptoms / 22) * 100).toFixed(1)}%)
                  </span>
                </label>
                <label className="field-label">
                  Symptom Severity Score:{" "}
                  <span className="score-label1">
                    {severityScore}/132 (
                    {((severityScore / 132) * 100).toFixed(1)}%)
                  </span>
                </label>
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Do your symptoms get worse with physical activity?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="worse_with_physical_activity">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "worse_with_physical_activity",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="worse_with_physical_activity"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do your symptoms get worse with mental activity?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="worse_with_mental_activity">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "worse_with_mental_activity",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="worse_with_mental_activity"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  If 100% is feeling perfectly normal, what percent of normal do
                  you feel?
                </label>
                <div className="slider-container">
                  <input
                    type="range"
                    name="percent_feeling_normal"
                    min="0"
                    max="100"
                    step="1"
                    value={values.percent_feeling_normal}
                    onChange={(e) =>
                      setFieldValue("percent_feeling_normal", e.target.value)
                    }
                    className="field-input"
                  />
                  <span className="slider-percentage">
                    {values.percent_feeling_normal}%
                  </span>{" "}
                </div>
                <ErrorMessage
                  name="percent_feeling_normal"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Please explain.</label>
                <Field
                  name="symptoms_explanation"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="symptoms_explanation"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/11")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                FINISH
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury6;
