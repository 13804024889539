import React from "react";
import { Link } from "react-router-dom";
import companyLogo from "../assets/img/CenSyn-resized.png";

function Navbar() {
  return (
    <div>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-white">
            <Link className="navbar-brand" to="/">
              <img
                src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                alt="Logo"
                style={{ marginLeft: "10px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon  " />
            </button>
            <div
              className="collapse navbar-collapse navbar-text bs-black"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto ">
                <li className="nav-item ">
                  <a className="nav-link active text-black" href="/technology">
                    Technology
                  </a>
                </li>
              </ul>
              <span className="navbar-text mr-5">
                <Link className="login-btn bg-info " to="/login">
                  Sign In
                </Link>
              </span>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
