import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPatientDetails, updatePatient } from "../../actions/patientActions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  id_number: Yup.string().required("Id is non/editable"),
  dob: Yup.date()
    .required("Date of Birth is required")
    .typeError("Invalid date format"),
  gender: Yup.string().required("Gender is required"),
  clinical_condition: Yup.string().required("Condition is required"),
});
const EditPatientInformation = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);

  const patientDetail = useSelector((state) => state.patientDetail);
  const { patient } = patientDetail;

  // Split name into first and last name
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    id_number: "",
    dob: "",
    gender: "",
    clinical_condition: "",
    category: "",
  });

  useEffect(() => {
    dispatch(getPatientDetails(token, params.id));
  }, [dispatch, token, params.id]);

  useEffect(() => {
    if (patient) {
      const [first_name, ...last_nameParts] = patient.name?.split(" ") || [""];
      setInitialValues({
        first_name: first_name,
        last_name: last_nameParts.join(" ") || "",
        id_number: patient.id_number || "",
        dob: patient.dob || "",
        gender: patient.gender || "",
        clinical_condition: patient.clinical_condition || "",
        category: patient.category || "",
      });
    }
  }, [patient]);

  const handleSubmit = (values) => {
    const name = `${values.first_name} ${values.last_name}`.trim();
    const payload = {
      ...values,
      name: name,
      age: patient?.age,
      phone_no: patient?.phone_no,
      address: patient?.address,
      blood_group: patient?.blood_group,
      id_number: patient?.id_number,
    };

    delete payload.first_name;
    delete payload.last_name;

    console.log("Payload to Backend:", payload);
    dispatch(updatePatient(token, params.id, payload))
      .then((response) => {
        toast.success("Patient Information updated successfully");
        history.push("/dashboard");
      })
      .catch((error) => {
        toast.error("Error! " + error.message);
      });
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Edit Patient Information</span>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">First Name</label>
                <Field name="first_name" className="field-input" />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Last Name</label>
                <Field name="last_name" className="field-input" />
                <ErrorMessage
                  name="last_name"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">ID Number</label>
                <Field name="id_number" className="field-input" disabled />
                <ErrorMessage
                  name="id_number"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Birth</label>
                <Field
                  name="dob"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="dob"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Gender</label>
                <Field as="select" name="gender" className="field-input">
                  <option value="" className="inputOption">
                    Select Gender
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Condition</label>
                <Field
                  as="select"
                  name="clinical_condition"
                  className="field-input"
                >
                  <option value="" className="inputOption">
                    Select Condition
                  </option>
                  <option value="1">Seizure</option>
                  <option value="2">Concussions</option>
                  <option value="3">Stroke</option>
                  <option value="4">Normal</option>
                  <option value="5">Altered Mental Status</option>
                </Field>
                <ErrorMessage
                  name="clinical_condition"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Category</label>
                <Field as="select" name="category" className="field-input">
                  <option value="" className="inputOption">
                    Select Category
                  </option>
                  <option value="research">Research</option>
                  <option value="clinic">Clinic</option>
                  <option value="both">Both</option>
                </Field>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.goBack()}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPatientInformation;
