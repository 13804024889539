import React, { useEffect, useState } from "react";
import { formatDate, formatTime, formatDOB } from "../../helper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getConcussionForms } from "../../actions/patientActions";
import ListHeading from "../list-record-components/list-header/listHeading";
import Table from "../react-table-component/ReactTable";
import { Link, useHistory } from "react-router-dom";
import close from "../../assets/img/svg/close-icon.svg";
import edit from "../../assets/img/svg/edit-icon.svg";
import historyIcon from "../../assets/img/svg/history-icon.svg";
import dotMenu from "../../assets/img/svg/dots-menu-icon.svg";
import "./concussionForms.css";

const genderMap = {
  M: "Male",
  F: "Female",
};
const formCategory = {
  first_visit: "First Visit",
  followup: "Follow Up",
};

export default function ConcussionFormsTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const token = useSelector((state) => state.loginData.token);
  const formsList = useSelector((state) => state.concussionForms);
  const [progress, setProgress] = useState(0);
  const { loading, concussionForms } = formsList;

  console.log(concussionForms);

  useEffect(() => {
    const simulateProgress = () => {
      let interval = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 20
        );
      }, 500);
      return () => clearInterval(interval);
    };

    simulateProgress();

    dispatch(getConcussionForms(token))
      .then(() => {
        setProgress(100);
      })
      .catch(() => {
        setProgress(100);
      });
  }, [dispatch, token]);

  // Delete logic
  // const handleDelete = () => {
  //   if (selectedRows.length == 0) {
  //     console.log("delete : ", selectedRows.length);
  //     toast.error("Please select at least one visit to delete.");
  //     return;
  //   }
  //   if (selectedRows.length > 1) {
  //     toast.error("You can't delete more than 1 record at a time.");
  //     return;
  //   }
  //   setShowDeleteDialog(true);
  // };

  const [searchField, setSearchField] = useState("");

  const handleSearch = (e) => {
    const searchField = e.target.value.toLocaleLowerCase();
    setSearchField(searchField);
  };

  const handleSelectedRowsChange = (selectedIds) => {
    console.log("selectedIds :: ", selectedIds);
    setSelectedRows(selectedIds);
  };

  const columns = React.useMemo(() => [
    {
      Header: "Patient ID",
      accessor: "id_number",
    },
    {
      Header: "Date of Birth",
      accessor: "date_of_birth",
      Cell: ({ row }) => {
        return <div>{row?.original?.date_of_birth ?? "N/A"}</div>;
      },
    },
    {
      Header: "Gender",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.gender ? genderMap[row.original.gender] : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Date of Test",
      Cell: ({ row }) => {
        return (
          <div>
            {" "}
            {row.original.date_of_test
              ? formatDate(row.original.date_of_test)
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Visit Type",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.form_category
              ? formCategory[row.original.form_category]
              : "N/A"}
          </div>
        );
      },
    },
    {
      Header: "Score",
      accessor: "symptom_severity_score",
    },
    {
      Header: "",
      accessor: "action",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            <div className="on-hover-options">
              <Link to={`#`}>
                <img src={historyIcon} title="Patient visits" alt="history" />
              </Link>
              <Link to={`#`}>
                <img src={edit} alt="edit" title="Edit patient details" />
              </Link>
              <Link to={`#`}>
                <img src={close} alt="close" title="Delete patient" />
              </Link>
            </div>
          </div>
        );
      },
    },
    // {
    //   Header: "",
    //   accessor: "dotMenu",
    //   disableSortBy: true,
    //   Cell: () => {
    //     return (
    //       <div>
    //         <img src={dotMenu} alt="menu" title="menu" />
    //       </div>
    //     );
    //   },
    // },
  ]);

  //   const filteredVisits = React.useMemo(() => {
  //     return (
  //       (visits?.data &&
  //         visits?.data.filter((visit) => {
  //           const patientName = visit.patient.name.toLowerCase();
  //           const dob = formatDOB(visit.patient.dob);
  //           const mrn = visit.patient.id.toString();

  //           const searchValue = searchField.toLowerCase();

  //           return (
  //             patientName.includes(searchValue) ||
  //             dob.includes(searchValue) ||
  //             mrn.includes(searchValue)
  //           );
  //         })) ||
  //       []
  //     );
  //   }, [visits?.data, searchField]);

  return (
    <>
      <section className="patient-list">
        <ListHeading title="Concussion Forms" handleSearch={handleSearch} />
        <div className="newForm">
          <Link to="/concussion-forms/add/1" className="link">
            {" "}
            + New Form
          </Link>
        </div>

        <div className="ms-2">
          {selectedRows?.length > 0 ? (
            <p>{selectedRows?.length} rows selected</p>
          ) : (
            ""
          )}
        </div>

        <Table
          columns={columns}
          data={concussionForms ?? []}
          // params={searchField}
          // fetchData={fetchData}
          // pageCount={ 1}
          loading={loading}
          tableOf="ConcussionForms"
          onSelectedRowsChange={handleSelectedRowsChange}
          progress={progress}
        />
      </section>
    </>
  );
}
