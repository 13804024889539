import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const CurrentInjury = () => {
  const validationSchema = Yup.object().shape({});
  const history = useHistory();

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm7")) || {
    injury_explanation: "",
    seen_other_physicians: "N",
    other_physicians_list: "",
    had_imaging: "N",
    imaging_list: "",
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm7", JSON.stringify(values));
    history.push("/concussion-forms/add/8");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">7/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Please describe how your injury occurred?
                </label>
                <Field
                  name="injury_explanation"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="injury_explanation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Have you seen any other physicians/clinicians for this injury?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="seen_other_physicians">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "seen_other_physicians",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="seen_other_physicians"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Please list (e.g. Urgent Care, Emergency, PCP)
                </label>
                <Field name="other_physicians_list" className="field-input" />
                <ErrorMessage
                  name="other_physicians_list"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Have you had any imaging following your concussion?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="had_imaging">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("had_imaging", option.value)
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="had_imaging"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Please list (e.g. CT, MRI)
                </label>
                <Field name="imaging_list" className="field-input" />
                <ErrorMessage
                  name="imaging_list"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/6")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury;
