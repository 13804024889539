export const EEG_FILE_REQUEST = "EEG_FILE_REQUEST";
export const EEG_FILE_SUCCESS = "EEG_FILE_SUCCESS";
export const EEG_FILE_FAIL = "EEG_FILE_FAIL";

export const EEG_FILE_FILTER_REQUEST = "EEG_FILE_FILTER_REQUEST";
export const EEG_FILE_FILTER_SUCCESS = "EEG_FILE_FILTER_SUCCESS";
export const EEG_FILE_FILTER_FAIL = "EEG_FILE_FILTER_FAIL";

export const PWELCH_PLOT_REQUEST = "PWELCH_PLOT_REQUEST";
export const PWELCH_PLOT_SUCCESS = "PWELCH_PLOT_SUCCESS";
export const PWELCH_PLOT_FAIL = "PWELCH_PLOT_FAIL";

export const COMPARE_RECORDING_REQUEST = "COMPARE_RECORDING_REQUEST";
export const COMPARE_RECORDING_SUCCESS = "COMPARE_RECORDING_SUCCESS";
export const COMPARE_RECORDING_FAIL = "COMPARE_RECORDING_FAIL";
