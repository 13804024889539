import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { listPatient } from "../../actions/patientActions";

const ConcussionIntakeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state.patientList);
  const token = useSelector((state) => state.loginData.token);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const { patients = [] } = patientList;

  useEffect(() => {
    if (patients && patients.length) {
      const filtered = patients
        .map((patient) => ({
          ...patient,
          isMatch: String(patient.id_number)
            .toLowerCase()
            .includes(String(searchTerm).toLowerCase()),
        }))
        .sort((a, b) => b.isMatch - a.isMatch);
      setFilteredPatients(filtered);
    }
  }, [searchTerm, patients]);

  const validationSchema = Yup.object().shape({
    id_number: Yup.string().required("Please enter a valid ID Number"),
  });

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm1")) || {
    patient: "",
    id_number: "",
    date_of_birth: null,
    gender: null,
    date_of_injury: null,
    dominant_hand: null,
    date_of_test: null,
    subject_group: "",
    form_category: "",
    notes: "",
  };

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch, token]);

  const handleSubmit = (values, { setSubmitting }) => {
    const validId = patients.some(
      (patient) => patient?.id_number === values.id_number
    );

    if (validId) {
      localStorage.setItem("ConcussionForm1", JSON.stringify(values));
      history.push("/concussion-forms/add/2");
    } else {
      toast.error("Please select a valid ID Number");
      setSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Concussion Intake Form</span>
        <span className="form-progress">1/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper" style={{ position: "relative" }}>
                <label className="field-label">ID Number</label>
                <Field
                  name="id_number"
                  className="field-input"
                  placeholder="Search by ID Number"
                  value={values.id_number ?? searchTerm}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSearchTerm(value);
                    setFieldValue("id_number", value);
                    setShowDropdown(true);
                  }}
                  onFocus={() => setShowDropdown(true)}
                  onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                />
                {showDropdown && filteredPatients.length > 0 && (
                  <ul className="dropdown-list">
                    {filteredPatients.map((patient) => (
                      <li
                        key={patient.id_number}
                        className="dropdown-item"
                        onClick={() => {
                          setSearchTerm(patient.id_number);
                          setFieldValue("id_number", patient.id_number);
                          setFieldValue("patient", patient.id);
                          setShowDropdown(false);
                        }}
                      >
                        {`${patient.id_number}`}
                      </li>
                    ))}
                  </ul>
                )}
                {!searchTerm && (
                  <ErrorMessage
                    name="id_number"
                    component="div"
                    className="error-message"
                  />
                )}
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Birth</label>
                <Field
                  name="date_of_birth"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="date_of_birth"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Gender</label>
                <Field as="select" name="gender" className="field-input">
                  <option value="" className="inputOption">
                    Select Gender
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Injury</label>
                <Field
                  name="date_of_injury"
                  type="date"
                  className="field-input"
                />
                <ErrorMessage
                  name="date_of_injury"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Dominant Hand</label>
                <Field as="select" name="dominant_hand" className="field-input">
                  <option value="">Select Dominant Hand</option>
                  <option value="R">Right</option>
                  <option value="L">Left</option>
                  <option value="A">Ambidextrous</option>
                </Field>
                <ErrorMessage
                  name="dominant_hand"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Date of Test</label>
                <Field
                  name="date_of_test"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="date_of_test"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Subject Group</label>
                <Field as="select" name="subject_group" className="field-input">
                  <option value="" className="options-font">
                    Select Subject Group
                  </option>
                  <option value="Cohort" className="options-font">
                    Cohort
                  </option>
                  <option value="Control" className="options-font">
                    Control
                  </option>
                </Field>
                <ErrorMessage
                  name="subject_group"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Visit Type</label>
                <Field as="select" name="form_category" className="field-input">
                  <option value="" className="options-font">
                    Select Visit Type
                  </option>
                  <option value="first_visit" className="options-font">
                    First Visit
                  </option>
                  <option value="followup" className="options-font">
                    Follow Up
                  </option>
                </Field>
                <ErrorMessage
                  name="form_category"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConcussionIntakeForm;
