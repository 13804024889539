import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { createSwayForm } from "../../actions/patientActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  mbess: Yup.number().typeError("mBESS must be a number"),
  // .required("mBESS is required"),
  memory: Yup.number().typeError("Memory must be a number"),
  // .required("Memory is required"),
  reaction_time: Yup.number().typeError("Reaction Time must be a number"),
  // .required("Reaction Time is required"),
  impulsive_control: Yup.number().typeError("Impulse Control must be a number"),
  // .required("Impulse Control is required"),

  inspection_time: Yup.number().typeError("Inspection Time must be a number"),
  // .required("Inspection Time is required"),
});

const SwayForm2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);

  const initialValues = {
    mbess: "",
    memory: "",
    reaction_time: "",
    impulsive_control: "",
    inspection_time: "",
  };

  const handleSubmit = (values) => {
    const formData = {
      ...values,
      ...JSON.parse(localStorage.getItem("swayFormData")),
    };
    dispatch(createSwayForm(token, formData))
      .then(() => {
        toast.success("Sway Form submitted successfully");
        localStorage.removeItem("swayFormData");
        history.push("/dashboard");
      })
      .catch((error) => {
        toast.error("Error! " + error.message);
      });
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">SWAY Scores Form</span>
        <span className="form-progress">2/2</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-heading-label">Balance</div>
              <div className="field-wrapper">
                <label className="field-label">mBESS</label>
                <Field name="mbess" className="field-input" />
                <ErrorMessage
                  name="mbess"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-heading-label">Cognitive</div>
              <div className="field-wrapper">
                <label className="field-label">Memory</label>
                <Field name="memory" className="field-input" />
                <ErrorMessage
                  name="memory"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Reaction Time</label>
                <Field name="reaction_time" className="field-input" />
                <ErrorMessage
                  name="reaction_time"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Impulse Control</label>
                <Field name="impulsive_control" className="field-input" />
                <ErrorMessage
                  name="impulsive_control"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Inspection Time</label>
                <Field name="inspection_time" className="field-input" />
                <ErrorMessage
                  name="inspection_time"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/sway-forms/1")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SwayForm2;
