import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/logins";
import "./header.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import manageIcon from "../../assets/img/svg/manageIcon.svg";
import settingIcon from "../../assets/img/svg/setting-icon.svg";
import signout from "../../assets/img/svg/Exit.svg";
import formsIcon from "../../assets/img/svg/file.svg";
import subjectsIcon from "../../assets/img/svg/user.svg";
import chartIcon from "../../assets/img/svg/chart.svg";
import studiesIcon from "../../assets/img/svg/studies-icon.svg";
import personIcon from "../../assets/img/svg/personalcard-icon.svg";
import trashIcon from "../../assets/img/svg/trashcan.svg";

const personalDropdownData = [
  // {
  //   title: "Patients",
  //   icon: personIcon,
  //   to: "/dashboard",
  // },
  {
    title: "Patient trash",
    icon: trashIcon,
    to: "/deleted-patient-list",
  },
  {
    title: "Visit trash",
    icon: trashIcon,
    to: "/deleted-visit-list",
  },
];

const researchDropdownData = [
  // {
  //   title: "Studies",
  //   icon: studiesIcon,
  //   to: "/liststudies",
  // },
  // {
  //   title: "Subjects",
  //   icon: subjectsIcon,
  //   to: "/listsubjects",
  // },
  {
    title: "Concussion Forms",
    icon: formsIcon,
    subMenu: [
      {
        title: "Concussion Intake",
        icon: formsIcon,
        to: "/concussion-forms",
      },
      {
        title: "SWAY",
        icon: formsIcon,
        to: "/sway-forms/1",
      },
    ],
  },
];

const Header = () => {
  const [activeNav, setActiveNav] = useState("");
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userData, setUserData] = useState();
  const [isFirmwareAdmin, setIsFirmwareAdmin] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicDropdownRef = useRef(null);
  const researchDropdownRef = useRef(null);

  const userDropdownRef = useRef(null);

  // Research sub menu of forms
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);
  const toggleSubMenu = (index) => {
    setOpenSubMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    if (localStorage.token) {
      let data = localStorage.token.split(".")[1];
      const parsedData = JSON.parse(atob(data));
      setUserData(parsedData.data[0]);
      setIsFirmwareAdmin(!!parsedData.data[0].is_firmware_admin);
    } else {
      setUserData({ doctor_id: 0, username: null, email: null });
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [localStorage, localStorage.token]);
  const handleClickOutside = (event) => {
    if (
      clinicDropdownRef.current &&
      !clinicDropdownRef.current.contains(event.target)
    ) {
      setActiveNav("");
    }

    if (
      researchDropdownRef.current &&
      !researchDropdownRef.current.contains(event.target)
    ) {
      setActiveNav("");
      setOpenSubMenuIndex(null);
    }

    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setShowUserMenu(false);
    }
  };

  const handleActiveNav = (nav) => {
    if (nav === activeNav) {
      setActiveNav("");
    } else {
      setActiveNav(nav);
    }
  };

  const toggleShowUserMenu = () => setShowUserMenu((prevState) => !prevState);

  const handleSignout = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/logout`).then((res) => {
      if (res.data.status === true) {
        dispatch(logoutUser());
        localStorage.clear();
        history.push({
          pathname: "/login",
        });
      }
    });
  };

  const getUserInitials = (username) => {
    if (!username) return "?";
    const names = username.trim().split(" ");
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (
      names[0].charAt(0) + names[names.length - 1].charAt(0)
    ).toUpperCase();
  };

  const closeMenus = () => {
    setActiveNav("");
    setShowUserMenu(false);
    setOpenSubMenuIndex(null);
  };

  return (
    <header className="header">
      <Link to={"/dashboard"}>
        <div className="logo-container">
          <img
            src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
            alt="censyn"
          />
        </div>
      </Link>
      <nav>
        <ul className="navigation">
          <li
            onClick={() => handleActiveNav("clinic")}
            className={`${activeNav === "clinic" && "active-nav"}`}
          >
            Clinic
          </li>
          {activeNav === "clinic" && (
            <div className="clinic-dropdown" ref={clinicDropdownRef}>
              {personalDropdownData.map((item, index) => (
                <DropDownItem
                  key={index}
                  item={item}
                  handleClose={closeMenus}
                />
              ))}
            </div>
          )}
          {activeNav === "research" && (
            <div className="research-dropdown" ref={researchDropdownRef}>
              {researchDropdownData.map((item, index) => (
                <div key={index} className="dropdown-item-wrapper">
                  <DropDownItem
                    item={item}
                    handleClose={closeMenus}
                    isSubmenuOpen={openSubMenuIndex === index}
                    toggleSubmenu={() => toggleSubMenu(index)}
                  />
                  {/* Submenu logic */}
                  {item.subMenu && openSubMenuIndex === index && (
                    <div className="sub-menu">
                      {item.subMenu.map((subItem, subIndex) => (
                        <DropDownItem
                          key={subIndex}
                          item={subItem}
                          handleClose={closeMenus}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          <li
            onClick={() => handleActiveNav("research")}
            className={`${activeNav === "research" && "active-nav"}`}
          >
            Research
          </li>
          {isFirmwareAdmin && (
            <li
              onClick={() => {
                handleActiveNav("firmware");
                history.push("/firmware");
              }}
              className={`${activeNav === "firmware" && "active-nav"}`}
            >
              Firmware
            </li>
          )}
        </ul>
      </nav>
      <div className="header-user-icon">
        <div className="user-initials-circle" onClick={toggleShowUserMenu}>
          {userData && userData.username
            ? getUserInitials(userData.username)
            : "?"}
        </div>
        {showUserMenu && (
          <div className="user-dropdown" ref={userDropdownRef}>
            <div className="user-dropdown-header">
              <div className="user-initials-circle">
                {userData && userData.username
                  ? getUserInitials(userData.username)
                  : "?"}
              </div>
              <div>
                <p className="header-username">
                  {userData && userData.username ? userData.username : "N/A"}
                </p>
                <p className="header-useremail">
                  {userData && userData.email ? userData.email : "N/A"}
                </p>
              </div>
            </div>
            {/* <DropDownItem
              item={{ title: "Manage Account", icon: manageIcon, to: "#" }}
              handleClose={closeMenus}
            /> */}
            <div className="divider"></div>
            {/* <DropDownItem
              item={{
                title: "Settings",
                icon: settingIcon,
                to: "/account-settings",
              }}
              handleClose={closeMenus}
            /> */}
            <DropDownItem
              item={{ title: "Sign out", icon: signout, to: "#" }}
              handleClick={handleSignout}
              handleClose={closeMenus}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;

export const DropDownItem = ({
  item,
  handleClose = () => {},
  handleClick = () => {},
  isSubmenuOpen = false,
  toggleSubmenu = () => {},
}) => {
  const { title, icon, to, subMenu } = item;
  const handleItemClick = () => {
    if (subMenu) {
      toggleSubmenu();
    } else {
      handleClick();
      handleClose();
    }
  };
  return (
    <>
      {to ? (
        <Link to={to}>
          <div className="dropdown-item" onClick={handleItemClick}>
            <p>{title}</p>
            <img src={icon} alt="personal" />
          </div>
        </Link>
      ) : (
        <div className="dropdown-item" onClick={handleItemClick}>
          <p>{title}</p>
          <img src={icon} alt="personal" />
        </div>
      )}
    </>
  );
};
