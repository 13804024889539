import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";

const CurrentInjury5 = () => {
  const history = useHistory();

  const initialValues = JSON.parse(
    localStorage.getItem("ConcussionForm11")
  ) || {
    anxiety: 0,
    irritability: 0,
    sadness: 0,
    nervousness: 0,
    trouble_falling_asleep: 0,
  };

  const handleSubmit = (values) => {
    localStorage.setItem("ConcussionForm11", JSON.stringify(values));
    history.push("/concussion-forms/add/12");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">11/12</span>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">More emotional</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="anxiety">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("anxiety", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="anxiety"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Irritability</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="irritability">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("irritability", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="irritability"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Sadness </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="sadness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("sadness", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="sadness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Nervous or anxious</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="nervousness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("nervousness", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="nervousness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Trouble falling asleep</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="trouble_falling_asleep">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("trouble_falling_asleep", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="trouble_falling_asleep"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms/add/10")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury5;
