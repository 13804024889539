import axios from "axios";
import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,
  PATIENT_VISITS_DETAIL_REQUEST,
  PATIENT_VISITS_DETAIL_SUCCESS,
  PATIENT_VISITS_DETAIL_FAIL,
  HOSPITAL_LIST_REQUEST,
  HOSPITAL_LIST_SUCCESS,
  HOSPITAL_LIST_FAIL,
} from "../constants/doctorConstants";

export const listDoctors = () => async (dispatch) => {
  try {
    dispatch({ type: DOCTOR_LIST_REQUEST });
    const { data } = await axios.get(`http://127.0.0.1:8000/doc/docs/`);

    dispatch({
      type: DOCTOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCTOR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getPatientVisitsDetail = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_VISITS_DETAIL_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient_visit_detail_v1/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_VISITS_DETAIL_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: PATIENT_VISITS_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getHospitalList = (token) => async (dispatch) => {
  try {
    dispatch({ type: HOSPITAL_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/hospital`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: HOSPITAL_LIST_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: HOSPITAL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};
