import React, { useEffect, useRef } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "react-chartjs-2";

import "react-toastify/dist/ReactToastify.css";
import "./recording2.css";

const PwelchGraph = ({ pwelchData }) => {
  const chartRefs = useRef({ ch1: null, ch2: null });
  Chart.register(zoomPlugin);

  const createGraph = (canvasRef, data, label, borderColor) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }

    return new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.frequency,
        datasets: [
          {
            label: label,
            data: data.power,
            backgroundColor: "rgba(0, 0, 255, 0)",
            borderColor: borderColor,
            borderWidth: 1,
            pointRadius: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
              maxRotation: 0,
              minRotation: 0,
              font: { size: 13 },
            },
            title: {
              display: true,
              text: "Frequency (Hz)",
              font: { size: 14 },
            },
          },
          y: {
            title: {
              display: true,
              text: "Power/frequency (dB/Hz)",
              font: { size: 14 },
            },
            ticks: {
              font: { size: 13 },
            },
          },
        },
        plugins: {
          legend: { display: true, position: "top", align: "end" },
          zoom: {
            pan: { enabled: true, mode: "x" },
            zoom: {
              wheel: { enabled: true, mode: "xy" },
              pinch: { enabled: true },
              mode: "x",
            },
          },
        },
        animation: false,
      },
    });
  };

  useEffect(() => {
    let chartInstance1 = null;
    let chartInstance2 = null;

    if (pwelchData) {
      const data1 = {
        frequency: pwelchData.f_pwelch_ch1,
        power: pwelchData.pxx_db_ch1,
      };
      const data2 = {
        frequency: pwelchData.f_pwelch_ch2,
        power: pwelchData.pxx_db_ch2,
      };

      chartInstance1 = createGraph(
        chartRefs.current.ch1,
        data1,
        "Channel 1",
        "rgba(0, 0, 255, 1)"
      );
      chartInstance2 = createGraph(
        chartRefs.current.ch2,
        data2,
        "Channel 2",
        "rgba(255, 0, 0, 1)"
      );

      return () => {
        if (chartInstance1) chartInstance1.destroy();
        if (chartInstance2) chartInstance2.destroy();
      };
    }
  }, [pwelchData]);

  return (
    <>
      {pwelchData ? (
        <>
          <div className="graph-box">
            <canvas ref={(el) => (chartRefs.current.ch1 = el)} height={200} />
          </div>
          <div className="graph-box">
            <canvas ref={(el) => (chartRefs.current.ch2 = el)} height={200} />
          </div>
        </>
      ) : (
        <div className="text-center">Loading Pwelch data...</div>
      )}
    </>
  );
};

export default PwelchGraph;
