import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";

const MedicalHistory2 = () => {
  const history = useHistory();

  const initialValues = JSON.parse(localStorage.getItem("ConcussionForm6")) || {
    medications: [],
    nameInput: "",
    doseInput: "",
  };

  const handleSubmit = (values) => {
    const formattedMedications = values.medications.map((medication) => ({
      name: medication.name.trim(),
      dose: medication.dose.trim(),
    }));

    localStorage.setItem(
      "ConcussionForm6",
      JSON.stringify({ medications: formattedMedications })
    );

    history.push("/concussion-forms/add/7");
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Medical History</span>
        <span className="form-progress">6/12</span>
      </div>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, values, setFieldValue, handleChange }) => {
          const addMedication = () => {
            const name = values.nameInput;
            const dose = values.doseInput;

            if (name.trim() && dose.trim()) {
              setFieldValue("medications", [
                ...values.medications,
                { name, dose },
              ]);
              setFieldValue("nameInput", "");
              setFieldValue("doseInput", "");
            }
          };

          const removeMedication = (index) => {
            const updatedMedications = values.medications.filter(
              (_, i) => i !== index
            );
            setFieldValue("medications", updatedMedications);
          };

          return (
            <Form
              className="form-wrapper"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.nodeName === "INPUT") {
                  e.preventDefault();
                }
              }}
            >
              <div className="form-content">
                <div className="field-wrapper">
                  <label className="field-label">
                    Which medications are you taking?
                  </label>
                  <Field
                    name="nameInput"
                    className="field-input"
                    value={values.nameInput || ""}
                    onChange={(e) => setFieldValue("nameInput", e.target.value)}
                  />
                  <ErrorMessage
                    name="nameInput"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="field-wrapper">
                  <label className="field-label">Dose</label>
                  <Field
                    name="doseInput"
                    className="field-input"
                    placeholder="Type a dose"
                    value={values.doseInput || ""}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="add-btn"
                    onClick={addMedication}
                  >
                    +
                  </button>
                  {values.medications.length > 0 && (
                    <div className="dose-list">
                      {values.medications.map((med, index) => (
                        <div key={index} className="dose-item">
                          <span className="dose-text">
                            {med.name} ({med.dose})
                          </span>
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => removeMedication(index)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  <ErrorMessage
                    name="dose"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="btn-wrapper">
                <button
                  type="button"
                  className="form-btns back-btn"
                  disabled={isSubmitting}
                  onClick={() => history.push("/concussion-forms/add/5")}
                >
                  BACK
                </button>
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                >
                  NEXT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MedicalHistory2;
