import React, { useState } from "react";
import axios from "axios";
import companyLogo from "../assets/img/logosync.png";
import Footer from "../components/Footer";
import Alert from "react-bootstrap/Alert";
import "../assets/css/login.css";

export default function ResetPassword() {
  const [validated, setValidated] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    const email = e.target[0].value;

    setButtonDisabled(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/password/reset/`, { email })
      .then((res) => {
        if (res.status === 200) {
          setShowSuccess(true);
          setShowError(false);
        } else {
          console.log("Unexpected response:", res);
        }
      })
      .catch((error) => {
        setShowError(true);
        setShowSuccess(false);
        console.error("Error resetting password:", error.response.data.message);
      })
      .finally(() => {
        setButtonDisabled(false);
        e.target.reset();
      });
  }

  return (
    <>
      <section className="login-main">
        <div className="container h-100">
          <div className="row justify-content-center p-0 m-0 h-100">
            <div className="col-xxl-5 col-lg-7 col-md-12 col-sm-12 py-5 h-100">
              <div className="content">
                <div className="div-image">
                  <a href="/">
                    <img
                      src="https://censyn-firmware-data.s3.amazonaws.com/CenSyn-logo.svg"
                      className="logo-image-class"
                      alt="company logo"
                    />
                  </a>
                </div>
                <div className="card card-border-radius w-100 p-lg-5 p-sm-2">
                  <div className="card-title">
                    <h2 className="p-3 pb-1">Reset Password</h2>
                  </div>
                  <div className="card-body">
                    {showError && (
                      <Alert variant="danger">
                        You will receive an email with instructions to reset
                        your password shortly if the email address provided is
                        registered with us.
                      </Alert>
                    )}
                    {showSuccess && (
                      <Alert variant="success">
                        Password reset email has been sent successfully!
                      </Alert>
                    )}

                    <form
                      className={`needs-validation ${
                        validated ? "was-validated" : ""
                      }`}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="form-label login-form-label"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control login-form-input"
                          id="email"
                          required
                        />
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">
                          Please fill out this field.
                        </div>
                      </div>
                      <div className="d-grid">
                        <button
                          type="submit"
                          className="btn text-light main-bg btn-style"
                          disabled={buttonDisabled}
                        >
                          <b>
                            {buttonDisabled
                              ? "Sending..."
                              : "Send reset e-mail"}
                          </b>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
